@import 'normalize.css';
// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;0,900;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300;400;500;600;700&display=swap');

$primary: #1f3f81;

@import 'node_modules/bootstrap/scss/bootstrap';

:root {
    --primary-color: #1f3f81;
    --secondary-color: #e5e8f4;

    --text-color-dark: #1f1f1f;
    --text-color-white: #fff;

    --width-size: 1170px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
    font-size: 62.5%;
}

body {
    // font-family: 'Roboto', sans-serif;
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    text-rendering: optimizeSpeed;
}

.svg-inline--fa {
    vertical-align: middle;
}

ul {
    list-style-type: none;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    color: unset;
}
a:hover {
    color: unset;
}

//Crollbar
html *::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
}

html *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgb(128 128 133);
}

html *::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: rgb(176 176 176);
}

//Dropdown
// .dropdown.category-dropdown-menu:hover .dropdown-menu {
//     display: flex;
//     margin-top: 0; // remove the gap so it doesn't close
// }

// .dropdown:hover .dropdown-menu {
//     display: flex;
//     margin-top: 0; // remove the gap so it doesn't close
// }

// .dropdown-menu {
//     border-radius: 0.5rem;
//     font-size: 2rem;
//     margin-bottom: 10px;
// }

//Dropdown
.dropdown1.category-dropdown-menu:hover .dropdown-menu {
    display: flex;
    margin-top: 0; // remove the gap so it doesn't close
}

.dropdown1:hover .dropdown-menu {
    display: flex;
    margin-top: 0; // remove the gap so it doesn't close
}

.dropdown-menu {
    border-radius: 0.5rem;
    font-size: 2rem;
    margin-bottom: 10px;
}

//Crollbar
html *::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
}

html *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgb(128 128 133);
}

html *::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: rgb(176 176 176);
}

// .container {
//     max-width: 1176px;
//     padding-left: 0;
//     padding-right: 0;
//     @media (max-width: 1199.98px) {
//         max-width: 936px;
//     }
//     @media (max-width: 991.98px) {
//         max-width: 720px;
//     }
//     @media (max-width: 420px) {
//         max-width: 380px;
//     }
// }

.accordion-button {
    font-size: 1.4rem;
}

.g-2,
.gy-2 {
    --bs-gutter-y: 2rem;
}

.form-control:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

//Home

.title-products-home {
    padding-top: 4rem;
    margin-bottom: 1.5rem;
    padding-left: 1rem;
    font-size: 2.4rem;
    font-weight: 500;
}
