.card-product {
    transition: all 0.5s;
}

.card-product:hover {
    transform: scale(1.03);
    /* transition-duration: 0.5s; */
}

.card-product:hover .card-btn {
    display: flex;
    transition: 10s;
}

.btn-group.card-btn {
    display: none;
}

.card-btn-item {
    padding: 8px 8px;
}

.card-new {
    position: absolute;
    font-size: 1.2rem;
    padding: 0 7px;
    background-color: var(--primary-color);
    color: var(--text-color-white);
    border-radius: 3px;
    top: 1rem;
    left: 1rem;
}
.btn.cus-btn {
    background: var(--secondary-color);
    border: none;
    padding: 8px 8px;
    transition: all 0.5s;
}

.btn.cus-btn:hover {
    background: var(--primary-color) !important;
}

.btn.cus-btn:hover .cus-btn-icon {
    color: white !important;
}
