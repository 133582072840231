.product-quality_item {
    align-items: center;
    padding: 5px 10px;
    border: 1px solid var(--gray-color2);
    background-color: transparent;
    color: var(--primary-text);
}

.product-quality_item2 {
    text-align: center;
    max-width: 50px;
    // padding: 5px 16px;
    // background-color: #262626;
    // color: var(--primary-text);
    border: 1px solid var(--gray-color2);
    outline: none;
}
